<template>
  <vf-header>
    <slot />
  </vf-header>
</template>

<script lang="ts" setup>
import { HeaderContextKey } from '#commerce/components/vf/header/context'
import type { MegaMenuContent } from '#types/components/cms/mega-menu'

const { $feature } = useNuxtApp()

const menuOpened = ref(false)
const menuHovered = ref(false)
const suggestionsOpened = ref(false)
const showPromoBar = ref(false)

const [megaMenu, { data: links }] = await Promise.all([
  useCms().getMegaMenu() as unknown as MegaMenuContent,
  useApi().internal.getUtility()
])

const sections = {
  megaMenu: {
    name: 'mega-menu',
    items: [megaMenu].filter(Boolean)
  },
  promoBar: {
    name: 'promo-bar',
    items: [{ ...megaMenu?.promoBar, type: 'VfCanvasPromoBar', id: 'promo-bar' }]
  },
  benefitBar: {
    name: 'benefit-bar',
    items: megaMenu?.benefitBar?.length
      ? [{ ...megaMenu.benefitBar[0], isFullWidth: true }]
      : []
  },
  openedSearchView: {
    name: 'opened-search-view',
    items: megaMenu?.openedSearchView?.length
      ? [{ ...megaMenu.openedSearchView[0], isFullWidth: true }]
      : []
  },
  openedSearchViewBelowRecommendations: {
    name: 'opened-search-view-below-recommendations',
    items: megaMenu?.openedSearchViewBelowRecommendations?.length
      ? [{ ...megaMenu.openedSearchViewBelowRecommendations[0], isFullWidth: true }]
      : []
  }
}

const stickyState = useSticky()
const isSomethingElseSticky = computed(() =>
  !!['headerPLP', 'productDetailsCta', 'stickyProductCta'].find((element) => !!stickyState.value[element]))

onMounted(() => {
  // Deferring promobar rendering to avoid layout shift of the page on initial loading
  setTimeout(() => showPromoBar.value = true, $feature.configPromoBarTimeout)
})

provide(HeaderContextKey, {
  menuOpened,
  menuHovered,
  suggestionsOpened,
  sections,
  showPromoBar,
  megaMenu,
  links: ref(links.value || []),
  isSomethingElseSticky
})
</script>
